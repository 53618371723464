import axios      from "./Requests/AxiosConfig";
import axiosUtils from "./Requests/AxiosUtils.js";

export default class LoginServices {

    realizarLogin(usuario, password) {
        let form = new FormData();
        form.append("usuario", usuario);
        form.append("password", password);
        form.append("apiKey", axiosUtils.getApikey());

        return new Promise((resolve, reject) => {
            axios.post("auth/login", form).then(resp => {
                let data = resp.data;
                if (data.codigo !== 200)
                    throw resp.data.mensaje;

                localStorage.setItem('token', data.datos.token);
                localStorage.setItem("autorId", data.datos.usuarioId);
                localStorage.setItem("usuario", data.datos.usuario);
                localStorage.setItem("nombreCompleto", data.datos.nombreCompleto);
                localStorage.setItem("permisos", data.datos.permisos);
                localStorage.setItem("ultimoAccesoFecha", data.datos.ultimoAccesoFecha);

                resolve();
            }).catch(error => axiosUtils.axiosCatch(error, err => reject(err)));
        });
    }
}
